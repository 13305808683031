import React from 'react';
import './Estilos/App.css';
import LogoOficina from './assets/img/office-work.gif';
import LogoGrow from './assets/img/Logo_H.png';
import LogoWhatsatpp from './assets/img/whatsapp.png'
import Logo_circulo from './assets/img/Logo_circulo.png'

function App() {
	
	return (
		// <div className='Contenedor-Principal'>

		// 	<div style={{height:'25vh', position:'relative'}}>

		// 		<div className='Contenedor-Header'>

		// 			<img
		// 				className='Imagen-Texto-Logo'
		// 				src={LogoGrow}
		// 			/>

		// 			<img
		// 				className='Imagen-Logo'
		// 				src={Logo_circulo}
		// 			/>

		// 		</div>

		// 		<div className='Contenedor-Cuerpo-Principal'>

		// 			<div className='Contenedor-Cuerpo'>
		// 				<p className='Primer-Texto-Cuerpo'>¡Pronto regresamos!</p>

		// 				<p className='Segundo-Texto-Cuerpo'><em>Estamos mejorando nuestra página</em></p>
		// 				<p className='Tercer-Texto-Cuerpo'>Firma Global de Consultoría  que ofrece servicios de estrategia, consultoría digital, tecnologías y operaciones.</p>
		// 			</div>
		// 		</div>
		// 	</div>




		// 	<div className='Contenedor-Texto-Principal'>

		// 		<div className='Contenedor-Cuerpo'>

		// 			<div className='Contenedor-Imagen-Principal'>
		// 				<img
		// 					className='Imagen-Oficina'
		// 					src={LogoOficina}
		// 				/>
		// 			</div>
		// 			<div className='Contenedor-Contacto-Principal'>
		// 				<a
		// 					href='https://wa.me/51951331671'
		// 					className='App-Boton-Contacto'
		// 					target='_blank'
		// 				>
		// 					<img
		// 						src={LogoWhatsatpp}
		// 						className='App-Boton-Logo-Whatsapp'
		// 					/>
		// 					<span className='App-Texto-Contacto'>CONTÁCTANOS</span>
		// 				</a>
		// 			</div>
		// 		</div>
		// 	</div>

		// 	<div className='Contenedor-Texto-Contacto'>
		// 		<p
		// 			className='Primer-Texto-Contacto'
		// 		>
		// 			Escríbenos:
		// 			<a
		// 				target='_blank'
		// 				className='Primer-Texto-Contacto'
		// 				href='mailto:Customer.Prime@grow-analytics.com.pe'
		// 			>
		// 				<em className='Estilo-Texto-Defecto'> Customer.Prime@grow-analytics.com.pe</em>
		// 			</a>
		// 		</p>

		// 		<p className='Primer-Texto-Contacto'>
		// 			Atención Telefónica: <em className='Estilo-Texto-Defecto'>(+51) 951 331 671</em>
		// 		</p>

		// 		<p className='Segundo-Texto-Contacto'>&copy; 2022	GROW ANALYTICS.</p>
		// 	</div>

		// </div>
		// <></>
		<div className='Container-Brand'>

			<div className='Container-Main-Brand'>
				<div className='Text-Main-Brand'>
					<div>UNA NUEVA ERA EN GROW ANALYTICS</div>
					<div>ESTÁ POR COMENZAR. ¡ESPÉRALA!</div>
				</div>

				<div className='Container-Button-Contact'>
					<a
						className='Button-Contact'
						href='https://wa.me/51995883528'
						target='_blank'
					>
						<div className='Text-Button-Contanct' style={{fontWeight:'700'}}>
							Contáctanos
						</div>
						<div style={{display:'flex', alignItems:'center'}}>
							<img
								src={LogoWhatsatpp}
								className='Logo-Whatsapp-Brand'
							/>
						</div>
					</a>
				</div>

			</div>

			<div className='Container-Footer'>
				<div style={{paddingTop:'5px',lineHeight:'1.2'}}>
					<div className='Info-Brand-Footer'>Escribenos: customer.prime@grow-analytics.com.pe</div>
					<div className='Info-Brand-Footer'>Atención Telefónica: (+51) 951 331 671</div>
				</div>
				<div style={{color:'#FFFFFF', display:'flex', alignItems:'center'}}>
					<div style={{textAlign:'initial'}}>
						&copy; <span className='Text-Cy' >2024</span> GROW ANALYTICS.
					</div>
				</div>
			</div>

		</div>
	)
}

export default App;